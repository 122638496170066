.PhoneInputStyle {
  display: block;
  width: 100%;
}

.PhoneInputInputStyle {
  width: 100% !important;
  height: 100% !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-width: 2px !important;
  border-color: #e5e7eb !important;
  border-radius: 0.375rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.PhoneInputInputStyle:focus {
  outline: none !important;
  border-color: #6366f1 !important;
  border-width: 2px !important;
  --tw-ring-color: #6366f1 !important;
}

.PhoneInputInputStyle::placeholder {
  color: #9ca3af !important;
}

.PhoneCountryButton {
  background-color: transparent !important;
  border: 0 !important;
  height: inherit;
  margin-left: 3px;
  margin-top: 2px;
  margin-bottom: 2px;
  vertical-align: middle;
}

.PhoneCountryButton.open {
  background-color: transparent !important;
  border: 0 !important;
}
